@import "@/scss/_variables.scss";

.font_tabs {
  font-family: $font_Regular;
  font-size: 1rem;
  color: #ffffff;
}
.nav-pd {
  padding: 0 10vw !important;
  z-index: 5000 !important;
}

.link-active .font_tabs {
  color: var(--v-primary-base) !important;
}

.v-tab {
  text-transform: none !important;
}

.v-tab:hover {
  opacity: 0.7;
}

//IPAD VERTICAL
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .v-list-item .v-list-item__subtitle,
  .v-list-item .v-list-item__title {
    line-height: 2.2 !important;
  }
  .v-list-item__title {
    align-self: center;
    font-size: 1.7rem !important;
  }
  .nav-pd {
    padding: 0 3vw !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .nav-pd {
    padding: 0 4vw !important;
  }
}

//IPAD LANDSCAPE
@media only screen and (min-width: 1000px) and (max-width: 1169px) and (orientation: landscape) {
  .v-list-item .v-list-item__subtitle,
  .v-list-item .v-list-item__title {
    line-height: 2.5 !important;
  }
  .v-list-item__title {
    align-self: center;
    font-size: 1.5rem !important;
  }
}
