@import "@/scss/_variables.scss";

.title {
  font-size: 3rem !important;
  color: #fff !important;
}
.text {
  color: white !important;
}
.apple-img {
  border: solid 0.5px rgba(193, 193, 193, 0.6) !important;
  border-radius: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .text {
    font-size: 0.7rem !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .text {
    font-size: 0.8rem !important;
  }
}
