.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  font-size: 0.8rem !important;
  color: #646c9a;
  font-family: $font_Regular !important;
  height: 50px;
}

.table-pointer tr {
  cursor: pointer !important;
}
tr.v-data-table__selected {
  background: var(--v-primary-base) !important;
  color: white !important;
}
tr.v-data-table__selected > td {
  color: white !important;
}

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.8rem !important;
  font-family: $font_Bold !important;
  vertical-align: top;
} */

/* .v-data-table-header th {
  white-space: nowrap;
} */
