//https://github.com/rstacruz/nprogress/blob/master/nprogress.css
#nprogress .bar {
  background: var(--v-primary-base);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}
