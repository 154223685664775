@import "@/scss/_variables.scss";

.flag_en {
  background: url("../assets/img/flags/united-kingdom.svg") no-repeat center
    center / cover;
}
.flag_de {
  background: url("../assets/img/flags/germany.svg") no-repeat center center /
    cover;
}
.flag_dk {
  background: url("../assets/img/flags/denmark.svg") no-repeat center center /
    cover;
}
