.swal2-title {
  color: black;
  font-size: 1.5em;
}

.swal2-html-container {
  color: #a1a8c3;
  font-size: 1.2em;
}

.swal2-styled.swal2-confirm {
  background-color: var(--v-success-base);
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(32 168 216 / 50%);
}

.swal2-styled.swal2-cancel {
  background-color: var(--v-error-base);
}

//LIGHT THEME
.toast_poup {
  background: var(--v-primary-base) !important;
}

.toast_text {
  color: white !important;
  font-size: 1rem !important;
}

.swal2-timer-progress-bar {
  background: white !important;
}

body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
  height: auto !important;
}

.swal2-popup {
  width: 25em;
  border-radius: 30px !important;
}
