.title-section {
  font-size: 2.5rem !important;
  font-weight: 800;
  text-align: center;
}

.title-grid {
  font-size: 1.3rem !important;
  font-weight: 800;
}
.price-grid {
  font-size: 0.9rem !important;
  color: rgba($color: #aaa, $alpha: 0.7);
  font-weight: 700;
}

.feature-title {
  font-size: 1rem !important;
  font-weight: 800;
}

.background-row {
  background-color: var(--v-primary-base);
  background-color: rgba($color: #aaa, $alpha: 0.3);
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .title-section {
    font-size: 1.5rem !important;
  }
  .title-grid {
    font-size: 0.9rem !important;
  }
  .price-grid {
    font-size: 1rem !important;
  }
  .feature-title {
    font-size: 0.8rem !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .title-section {
    font-size: 2rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) and (orientation: landscape) {
  .title-section {
    font-size: 2rem !important;
  }
  .title-grid {
    font-size: 1rem !important;
  }
  .price-grid {
    font-size: 0.7rem !important;
  }
  .feature-title {
    font-size: 0.9rem !important;
  }

  .btn {
    font-size: 0.7rem !important;
  }
}
