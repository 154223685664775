body {
  /* background-color: $color_3; */
  font-size: 14px;
  font-family: $font_Regular !important;
  background-color: #ffffff;
  letter-spacing: 1px !important;
}
html {
  font-size: 16px; /* Your desired root font size in pixels */
}
.v-toolbar__content {
  width: 100% !important;
}
.container-layout {
  max-width: 1280px !important;
  margin: 0 auto !important; /* Center the content horizontally */
}
@media screen and (max-width: 1200px) {
  .container-layout {
    padding: 0 20px; /* Add padding for small screens */
  }
}
a {
  text-decoration: none !important;
}

.text-primary {
  color: var(--v-primary-base);
}

.text-secondary {
  color: var(--v-secondary-base);
}

.text-muted {
  color: #8c98a4 !important;
}

.text-transform {
  text-transform: none;
}

.rounded-corners-sheet {
  border-radius: 70px !important;
}

//fonts
.title-bold-big {
  font-family: $font_Bold !important;
  font-size: 2.8rem !important;
  line-height: 3.5rem !important;
}

.title-bold {
  font-family: $font_Bold !important;
  font-size: 2rem !important;
  line-height: 2.5rem !important;
}

.subtitle-bold {
  font-family: $font_Bold !important;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-regular {
  font-family: $font_Regular !important;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
}

.text-bold-list {
  font-family: $font_Bold !important;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .title-bold-big {
    font-size: 2.2rem !important;
    line-height: 2.6rem !important;
  }

  .title-bold {
    font-size: 1.8rem !important;
    line-height: 2.2rem !important;
  }

  .subtitle-bold {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
  }

  .text-regular {
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }

  .text-bold-list {
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) and (orientation: landscape) {
  .title-bold-big {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .title-bold {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
  }

  .subtitle-bold {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }

  .text-regular {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }

  .text-bold-list {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) and (orientation: landscape) {
  .title-bold-big {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .title-bold {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
  }

  .subtitle-bold {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }

  .text-regular {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }

  .text-bold-list {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

//IPAD VERTICAL
@media only screen and (min-width: 768px) and (max-width: 999px) and (orientation: portrait) {
  .title-bold-big {
    font-size: 2.5rem !important;
    line-height: 3rem !important;
  }

  .title-bold {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .subtitle-bold {
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
  }

  .text-regular {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
  .text-bold-list {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

//IPAD PRO VERTICAL
@media only screen and (min-width: 1000px) and (max-width: 1169px) and (orientation: portrait) {
  .title-bold-big {
    font-size: 2.5rem !important;
    line-height: 3rem !important;
  }

  .title-bold {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .subtitle-bold {
    font-size: 1.5rem !important;
    line-height: 1.8rem !important;
  }

  .text-regular {
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
  }

  .text-bold-list {
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
  }
}

//IPAD LANDSCAPE
@media only screen and (min-width: 1000px) and (max-width: 1169px) and (orientation: landscape) {
  .title-bold-big {
    font-size: 2.5rem !important;
    line-height: 3rem !important;
  }

  .title-bold {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .subtitle-bold {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .text-regular {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }

  .text-bold-list {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }
}
