@font-face {
  font-family: "Pangram-Regular";
  src: local("Pangram-Regular"),
  url(/fonts/Pangram-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Pangram-Bold";
  src: local("Pangram-Bold"), url(/fonts/Pangram-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Bold";
  src: local("SourceSansPro-Bold"), url(/fonts/SourceSansPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Regular";
  src: local("SourceSansPro-Regular"), url(/fonts/SourceSansPro-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Regular";
  src: local("SourceSansPro-Regular"), url(/fonts/SourceSansPro-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Semibold";
  src: local("SourceSansPro-Semibold"), url(/fonts/SourceSansPro-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Black";
  src: local("SourceSansPro-Black"), url(/fonts/SourceSansPro-Black.ttf) format("truetype");
}
