.bg_half_transparent {
  background: rgba(255,255,255, .5);
}

.wask_loader {
  position: fixed !important;
  z-index: 1000 !important;
  height: 100vh;
}

.wask_loader > div.v-spinner > div {
  margin: 0 auto;
}
