@import "@/scss/_variables.scss";

.join-btn {
  background-color: #131313 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-family: "SourceSansPro-Regular", serif !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  border: 0 !important;
  box-sizing: border-box !important;
  border-radius: 24px !important;
  width: 140px !important;
  height: 52px !important;
}
.navbar {
  width: 100% !important;
  max-width: 1280px !important;
  margin: 0 auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}
.navbar div {
  padding: 0 !important;
}
.v-app-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  background-color: #ffffff !important;
  z-index: 1000 !important; /* Adjust this value as needed */
}
.app-name {
  font-family: "SourceSansPro-Bold", sans-serif !important;
  font-size: 40px;
  font-weight: 800 !important;
  line-height: 52px !important;
  color: #131313;
}
@media (max-width: 1280px) {
  /* You can adjust the value (600px) based on your needs */
  .navbar {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .join-btn {
    width: 120px !important;
    height: 48px !important;
    font-size: 12px !important;
    line-height: 25px !important;
  }
  .app-name {
    font-size: 30px;
    line-height: 42px !important;
  }
}

@media screen and (max-width: 480px) {
  .join-btn {
    width: 100px !important;
    height: 40px !important;
    font-size: 10px !important;
    line-height: 20px !important;
  }
  .app-name {
    font-size: 25px;
    line-height: 32px !important;
  }
}
