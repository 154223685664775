@import "@/scss/_variables.scss";

.tabs-container {
  border: solid;
  border-color: rgba($color: #aaa, $alpha: 0.5);
  border-width: 0.2px;
}

.v-tab {
  text-transform: none !important;
}

.v-tab:hover {
  opacity: 0.7;
}
