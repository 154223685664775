.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 0.0625rem solid #e7eaf3;
}

.v-input {
  font-size: 14px !important;
}

.search-input .v-text-field__details,
.input-details-disable .v-text-field__details {
  display: none !important;
}
